<template>
  <div class="defrayBox">
    <div>
      <img
        class="defrayImg"
        v-if="fettle == '0'"
        src="../../assets/images/lodding.gif"
        alt=""
      />
      <img
        class="defrayIcon"
        v-if="fettle == '1'"
        src="../../assets/images/finish.png"
        alt=""
      />
      <img
        class="defrayIcon"
        v-if="fettle == '2' || fettle == '3'"
        src="../../assets/images/icon-Notice.png"
        alt=""
      />
    </div>
    <div class="defrayNum1" v-if="isShowNo == 1">订单编号:{{ orderNo }}</div>
    <div class="defrayNum1" v-if="isShowNo == 1">客户名:{{ SendOrgName }}</div>
    <div class="defrayNum" v-if="isShowNo == 1">金额:{{ OrderCost }}/元</div>
    <div class="status" v-if="fettle == '0'">正在支付中,请耐心等待!</div>
    <div class="status" v-if="fettle == '1'">支付完成</div>
    <div class="status" v-if="fettle == '2'">支付信息异常,请重试!</div>
    <div class="status" v-if="fettle == '3'">{{ errorMsg }}</div>

    <div
      class="back-index"
      v-if="fettle == '2' || fettle == '3'"
      @click="goBackBtn()"
    >
      返回首页
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      errorMsg: "--",
      isShowNo: 0,
      SendOrgName: "",
      OrderCost: 0,
      orderNo: "",
      BatchNo: "",
      fettle: 0, //0加载  1完成  2错误
      OrgId: "",
      openid:"",
      url: "https://vsp.allinpay.com/apiweb/h5unionpay/unionorder",
      params: {
        appid: "",
        body: "",
        charset: "UTF-8",
        cusid: "",
        notify_url: "",
        randomstr: "",
        reqsn: "",
        returl: "",
        sign: "",
        signtype: "",
        trxamt: "",
        version: "",
      },
	  
	  MasterBatchNo: "",
	  Ticks: 0,
    };
  },
  created() {
    var that = this;
    if (location.search) {
      const datas = that.public.urlObj(location.href);
      that.BatchNo = datas.BatchNo;
	  that.Ticks = datas.Ticks?datas.Ticks:0;
	  that.MasterBatchNo = datas.MasterBatchNo?datas.MasterBatchNo:"";
    }
    that.OrgId = that.public.getConfig.defaultOrgId;
    localStorage.setItem("orgId", that.OrgId);
    that.getLoginByOrder().then(r=>{
      sessionStorage.setItem("member_token",r.rows);
      localStorage.setItem("orgId", r.extend.currentOrgId);
      sessionStorage.setItem("customer", r.extend.customerId);
      that.getUserInfo();
    }).catch(r=>{
      that.errorMsg = r.errorMsg;
       that.fettle='3';
    });
  },
  methods: {
    getLoginByOrder(){ //自动登陆
      var that = this;
      return new Promise((resolve, reject) =>{
          that.public.request("post","/api/AppLogin/OrderNoByLogin",{
            ReceiveOrgId:that.OrgId,
            OrderNo:that.BatchNo,
			MasterBatchNo:that.MasterBatchNo
          },(res)=>{
            if (res.data.errorCode == "00") {
                  resolve(res.data);
                } else {
                  reject(res.data);
                }
          });
      }); 
    },
    getUserInfo() {
      var that = this;
      that.public.request("post","/api/OrderPay/GetQrUnionPaySetting",{
		  OrgId: that.OrgId,
		  BatchNo: that.BatchNo,
		  MasterBatchNo: that.MasterBatchNo,
		  Ticks: that.Ticks
	  },(res) => {
          if (res.data.errorCode == "00") {
            try {
              that.orderNo = res.data.extend.order[0].order.OrderNo;
              that.SendOrgName = res.data.extend.order[0].order.SendOrgName;
              that.OrderCost = res.data.extend.order[0].order.OrderCost;
              that.isShowNo = 1;
            } catch {}
            that.params = res.data.rows;
            that.createPayForm(that.url, that.params);
          } else {
            that.errorMsg = res.data.errorMsg;
            that.fettle = 3;
          }
        }
      );
    },
    goBackBtn() {
      var href = "/";
      let mchData ={action:'jumpOut', jumpOutUrl:href};
    	let postData = JSON.stringify(mchData);
    	parent.postMessage(postData,'https://payapp.weixin.qq.com');
      // top.location.href = "https://app.yaomengzhu.com";
    },
    createPayForm: function (url, params) {
      let doc = document.createElement("form");
      doc.action = url;
      doc.method = "post";
      doc.style.display = "none";
      for (let k in params) {
        console.log("k value:", k, decodeURIComponent(params[k]));
        let opt = document.createElement("input");
        opt.name = k;
        opt.value = decodeURIComponent(params[k]);
        doc.appendChild(opt);
      }
      let opt = document.createElement("input");
      opt.type = "submit";
      opt.value = "aaa";
      doc.appendChild(opt);
      document.body.appendChild(doc);
      doc.submit();
      document.body.removeChild(doc);
    },
  },
};
</script>

<style scoped>
.defrayBox {
  text-align: center;
  margin-top: 2.5rem;
  font-size: 0.24rem;
}
.status {
  font-size: 0.36rem;
}
.defrayImg {
  width: 45%;
  margin: 0 auto;
}
.defrayIcon {
  width: 25%;
  margin: 0 auto;
  padding: 0.8rem 0;
}
.defrayNum {
  margin-bottom: 0.5rem;
  color: rgb(133, 133, 133);
}
.defrayNum1 {
  margin-bottom: 0.1rem;
  color: rgb(133, 133, 133);
}
.back-index {
  position: fixed;
  left: 0;
  bottom: 3.8rem;
  width: 1.5rem;
  height: 0.6rem;
  border-radius: 0 0.3rem 0.3rem 0;
  background: rgb(255, 145, 0);
  line-height: 0.6rem;
  text-align: center;
  color: #fff;
  font-size: 0.3rem;
  z-index: 3;
}
</style>